import { adventureToursHeroSection } from "../../data/Home/dataStorage";

export const thingstocarray = [
  {
    sectionTitle: "Clothing",
    content: [
      "Warm layers (thermals, fleece, down jacket)",
      "Waterproof and windproof outer layers",
      "Headgear: hat, scarf, sunglasses, sun hat/cap",
      "Insulated, waterproof boots and woolen socks",
    ],
  },
  {
    sectionTitle: "Health and Safety",
    content: [
      "Altitude sickness medication (consult a doctor)",
      "First aid kit and personal medications",
    ],
  },
  {
    sectionTitle: "Miscellaneous",
    content: [
      "Camera with accessories, power bank, and phone",
      "Personal identification, permits, and passport size photos",
      "Sufficient cash",
      "High-energy snacks",
      "Toiletries and quick-dry travel towel",
      "Sunscreen, lip balm with UV protection",
      "Reusable water bottle and thermos for hot water",
    ],
  },
];

export const individualExpedition = [
  {
    individualExpeditionType: "spitiValleyOverlanding",
    individualExpeditionName: "Spiti Valley Adventure",
    carouselData: adventureToursHeroSection,
    location: "Spiti Valley, Himachal Pradesh",
    duration: "8 Days",
    grade: "Any",
    maxAltitude: "4700 m",
    bestSeason: "June, July, August, September and October",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Nestled amid the Himalayas in Himachal Pradesh, Spiti Valley, at an altitude of 3,650 to 4500 metres, captivates with its stark beauty. The high-altitude desert, crisscrossed by the Spiti River, boasts ancient monasteries like Ki and Tabo, resonating with Buddhist spirituality.",
    aboutExpeditionExperienced:
      "Accessible via challenging roads from Manali or Shimla, the region reveals remote villages like Kibber and Dhankar. With snow leopards and Himalayan blue sheep inhabiting its rugged terrain, Spiti offers a unique ecosystem. Despite harsh winters limiting accessibility, adventurous souls are drawn to its serene landscapes and the mesmerising Chandratal Lake, making Spiti a haven for cultural exploration and high-altitude escapades.",
    itineraryData: [
      {
        day: "1",
        title: "Shimla to Kalpa (6-7 hours)",
        details: [
          "Route: Shimla – Narkanda – Rampur – Tranda Dhank – Kalpa",
          "Narkanda: A beautiful hill station, enjoy snow-clad peaks and pine forests.",
          "Rampur: A scenic drive along the Sutlej River.",
          "Tranda Dhank: A thrilling drive on a narrow road cut into the cliffside.",
          "Kalpa: Situated at 2960m, this village offers stunning views of the Kinner Kailash range.",
          "Accommodation: Stay at a luxury hotel in Kalpa.",
          "Meals: Dinner included.",
        ],
      },
      {
        day: "2",
        title: "Kalpa to Tabo (5-6 hours)",
        details: [
          "Route: Kalpa – Khab Sangam Bridge – Nako Lake – Tabo",
          "Khab Sangam Bridge: The confluence of the Spiti and Sutlej rivers, a dramatic landscape.",
          "Nako Lake: A serene frozen lake at 3660m with views of snow-capped peaks.",
          "Tabo: Visit one of the oldest Buddhist monasteries in the world, established in 996 AD.",
          "Accommodation: Warm guest house in Tabo.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "3",
        title: "Tabo to Kaza via Dhankar (4-5 hours)",
        details: [
          "Route: Tabo – Dhankar Monastery – Kaza",
          "Tabo Monastery: Morning visit to explore the ancient murals and shrines.",
          "Dhankar Monastery: Perched on a cliff, this ancient monastery offers breathtaking views of the Spiti valley.",
          "Kaza: The commercial hub of Spiti, surrounded by a rugged landscape.",
          "Accommodation: Amazing hotel in Kaza.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "4",
        title: "Kaza Adventure Day (Chichum, Langza, Kibber)",
        details: [
          "Route: Kaza – Chichum Bridge – Langza – Key Monastery – Kibber Village",
          "Chichum Bridge: The highest bridge in Asia, offering stunning canyon views.",
          "Langza: Known for the massive Buddha statue overlooking the village and fossils.",
          "Key Monastery: The largest and most famous monastery in Spiti.",
          "Kibber Village: One of the highest inhabited villages in the world at 4205m.",
          "Accommodation: Stay in a local homestay for an authentic Spitian experience.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "5",
        title: "Kaza to Nako via Komic (5-6 hours)",
        details: [
          "Route: Kaza – Komic – World’s Highest Post Office – Nak",
          "World’s Highest Post Office (Hikkim): Send postcards to loved ones from this remote location.",
          "Nako: Return to the charming village of Nako for the night.",
          "Accommodation: Comfortable guest house in Nako.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "6",
        title: "Nako to Rakcham via Chitkul (5-6 hours)",
        details: [
          "Route: Nako – Sangla – Chitkul – Rakcham",
          "Sangla Valley: Known for its apple orchards and breathtaking views.",
          "Chitkul: The last inhabited village on the Indo-Tibetan border, surrounded by mountains.",
          "Rakcham: A peaceful village by the Baspa River, perfect for unwinding.",
          "Accommodation: Stay at a scenic location in Rakcham.",
          "Meals: Breakfast and dinner included.",
        ],
      },
      {
        day: "7",
        title: "Rakcham to Shimla (7-8 hours)",
        details: [
          "Route: Rakcham – Sangla – Rampur – Narkanda – Shimla",
          "A relaxed drive back to Shimla, soaking in the beauty of the landscape.",
          "Final drop at Shimla after an unforgettable Spiti adventure.",
          "Meals: Breakfast included.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Inclusion",
        content: [
          "Self-Drive Option: Vehicles like Thar, Jimny, Fortuner, Isuzu, Hilux, Yodha.",
          "Backup Team: 4x4 experienced drivers and guides.",
          "Medical Assistance: Oxygen cylinders for high altitude support.",
          "Accommodation: Luxury hotels, guest houses, and homestays.",
          "Meals: Breakfast and dinner provided daily.",
          "Permits: All required permits for the Spiti region.",
          "Special Stops: Offbeat locations for unique experiences.",
        ],
      },
    //   {
    //     sectionTitle: "Meals",
    //     content: ["Dinner and Breakfast all days"],
    //   },
    //   {
    //     sectionTitle: "Transportation",
    //     content: [
    //       "The cost of the vehicle (either self-driven or with a driver) for the entire road trip.",
    //     ],
    //   },
    //   {
    //     sectionTitle: "Permits",
    //     content: [
    //       "Any necessary permits for travelling through restricted or protected areas.",
    //     ],
    //   },
    // ],
    // exclusion: [
    //   {
    //     sectionTitle: "Personal Expenses",
    //     content: [
    //       "Expenses such as personal shopping, additional meals, or any personal activities not specified in the itinerary.",
    //     ],
    //   },
    //   {
    //     sectionTitle: "Lunch on any day",
    //     content: [],
    //   },
    //   {
    //     sectionTitle: "Tips and Gratuities",
    //     content: [
    //       "Tips for guides, drivers, and other service staff are usually not included.",
    //     ],
    //   },
    //   {
    //     sectionTitle: "Transportation to the Starting Point",
    //     content: [
    //       "The journey often starts from a specific location. Participants are responsible for reaching that location.",
    //     ],
    //   },
    //   {
    //     sectionTitle: "Additional Activities",
    //     content: [
    //       "Any optional activities or excursions not mentioned in the itinerary.",
    //     ],
    //   },
    //   {
    //     sectionTitle: "Emergency Expenses",
    //     content: [
    //       "Any expenses related to medical emergencies or unexpected events not covered by insurance.",
    //     ],
    //   },
    //   {
    //     sectionTitle: "Personal Gear",
    //     content: [
    //       "Participants are responsible for their personal travel gear, clothing, and equipment.",
    //     ],
    //   },
    //   {
    //     sectionTitle: "Vehicle Maintenance",
    //     content: [
    //       "If participants are using a self-driven vehicle, they may be responsible for vehicle maintenance and repairs.",
    //     ],
    //   },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "ladakhOverlanding",
    individualExpeditionName: "Ladakh Adventure",
    carouselData: adventureToursHeroSection,
    location: "Ladakh",
    duration: "9 Days",
    grade: "Any",
    maxAltitude: "5883 m",
    bestSeason: "June, July, August, September and October",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Ladakh, a high-altitude region in northern India, captivates with its stark beauty and rugged landscapes. Nestled amidst the Himalayas, Ladakh boasts breathtaking mountain vistas, serene lakes like Pangong and Tso Moriri, and ancient monasteries such as Thiksey and Hemis. Known as the 'Land of High Passes,' it offers adventurous souls thrilling journeys through passes like Khardung La. ",
    aboutExpeditionExperienced:
      "The cultural tapestry is woven with Tibetan Buddhism, evident in prayer flags fluttering in the wind and monks chanting in monasteries. With a unique blend of tradition and tranquility, and numerous high altitude lakes, wild-life and mind boggling places, Ladakh remains an enchanting destination for those seeking awe-inspiring natural wonders and spiritual solace.",
    itineraryData: [
      {
        day: "1",
        title: "Arrive in Leh",
        details: [
          "Altitude: Leh welcomes you at 3,500 metres (11,500 feet) above sea level.",
          "Highlights: Upon arrival, take it easy to acclimatise. Explore Shanti Stupa and the local market today. Take it easy on food. Eat light and allow the body to acclimatise well. ",
        ],
      },
      {
        day: "2",
        title: "Leh Local",
        details: [
          "Altitude: Most places in Leh hover around 3,500 meters.",
          "Highlights: Start early in the morning, visit Hemis & Thiksey Monastery. Visit Shey Palace. Visit Sangam point and Gurudwara Pathar Sahib. Evening at Leh Market.",
        ],
      },
      {
        day: "3",
        title: "Leh to Nubra via Khardungla",
        details: [
          "Altitude: Conquer Khardung La, one of the world's highest motorable passes at 5,359 meters (17,582 feet).",
          "Highlights: Marvel at Khardung La Pass, reach Nubra Valley, explore Diskit Monastery, and experience the Hunder sand dunes.",
        ],
      },
      {
        day: "4",
        title: "Nubra to Turtuk to Nubra",
        details: [
          "Altitude: Turtuk sits at 3,000 meters (9,800 feet).",
          "Highlights: Immerse yourself in the charm of Turtuk, known for apricot orchards and cultural richness. Return to Nubra in the evening.",
        ],
      },
      {
        day: "5",
        title: "Nubra to Pangong Tso",
        details: [
          "Altitude: Pangong Tso beckons at 4,350 meters (14,270 feet).",
          "Highlights: Traverse stunning landscapes to reach Pangong Lake. Absorb the breathtaking beauty of the lake and its surroundings.",
        ],
      },
      {
        day: "6",
        title: "Pangong Tso to Hanle",
        details: [
          "Altitude: Hanle stands tall at 4,500 meters (14,764 feet).",
          "Highlights: Discover the Hanle Observatory and Monastery. Delight in clear night skies for stargazing in this remote region.",
        ],
      },
      {
        day: "7",
        title: "Hanle to Tso Morori",
        details: [
          "Altitude: Tso Moriri awaits at 4,522 meters (14,836 feet).",
          "Highlights: Umling La, Explore Tso Moriri Lake, a high-altitude marvel surrounded by barren mountains. Visit Korzok Monastery on the lake's banks.",
        ],
      },
      {
        day: "8",
        title: "Tso Morori to Leh via Tso Kar",
        details: [
          "Altitude: Tso Kar sits gracefully at 4,530 meters (14,862 feet).",
          "Highlights: Enjoy the scenic beauty of Tso Kar Lake. Continue the journey back to Leh, passing through Tanglang La, one of the world's highest mountain passes",
        ],
      },
      {
        day: "9",
        title: "Departure",
        details: ["After breakfast in the morning, take a flight back home."],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Enjoy comfortable stays in selected hotels/ camps/home-stay/guest houses throughout the journey.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: ["Dinner from Day 1 to Breakfast on Day 9"],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Experience hassle-free travel with included transportation for all planned excursions and transfers.",
        ],
      },
      {
        sectionTitle: "Permits",
        content: [
          "Assistance in obtaining necessary permits for restricted areas, ensuring a smooth travel experience.",
        ],
      },
      {
        sectionTitle: "Sightseeing Tours",
        content: [
          "Explore Ladakh's cultural and natural wonders with guided tours wherever possible.",
        ],
      },
      {
        sectionTitle: "Oxygen Support",
        content: [
          "As Ladakh boasts high altitudes, oxygen support is provided as needed to ensure the well-being of travellers.",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Expenses such as personal shopping, additional meals, or any personal activities not specified in the itinerary.",
        ],
      },
      {
        sectionTitle: "Tips and Gratuities",
        content: [
          "Tips for guides, drivers, and other service staff are usually not included.",
        ],
      },
      {
        sectionTitle: "Transportation to the Starting Point",
        content: [
          "The journey often starts from a specific location. Participants are responsible for reaching that location.",
        ],
      },
      {
        sectionTitle: "Additional Activities",
        content: [
          "Any optional activities or excursions not mentioned in the itinerary.",
        ],
      },
      {
        sectionTitle: "Emergency Expenses",
        content: [
          "Any expenses related to medical emergencies or unexpected events not covered by insurance.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Participants are responsible for their personal travel gear, clothing, and equipment.",
        ],
      },
      {
        sectionTitle: "Vehicle Maintenance",
        content: [
          "If participants are using a self-driven vehicle, they may be responsible for vehicle maintenance and repairs.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "zanskarOverlanding",
    individualExpeditionName: "Zanskar Overlanding",
    carouselData: adventureToursHeroSection,
    location: "Zanskar, Ladakh",
    duration: "8 Days",
    grade: "Any",
    maxAltitude: "5000 m",
    bestSeason: "June, July, August, September and October",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Zanskar, nestled within the Union Territory of Ladakh in northern India, is a captivating region celebrated for its breathtaking landscapes and rich cultural heritage. Surrounded by the towering peaks of the Himalayas and the Zanskar Range, it stands as one of the most isolated and challenging-to-reach areas on the Indian subcontinent. The Zanskar River, flowing through this high-altitude desert, freezes during winter, creating the renowned Chadar Trek—an adventurous journey across its icy expanse.",
    aboutExpeditionExperienced:
      "The cultural tapestry of Zanskar is woven with the threads of Tibetan Buddhism, evident in its ancient monasteries. Despite its remoteness, Zanskar has become a destination for adventure enthusiasts, offering trekking, river rafting, and an exploration of its unique monastic heritage. However, the region faces challenges due to its harsh climate, and its residents, primarily of Tibetan descent, have adapted their lives to the demanding conditions, relying on agriculture and livestock for sustenance. Zanskar, with its stunning natural beauty and resilient local culture, provides a truly distinctive and immersive experience for those who venture into its remote embrace.",
    itineraryData: [
      {
        day: "1",
        title: "Manali",
        details: [
          "Begin your adventure in the enchanting town of Manali, nestled in the lap of the Himalayas. The air is crisp, and the altitude sets the stage for the journey ahead.",
        ],
      },
      {
        day: "2",
        title: "Manali to Jispa",
        details: [
          "Depart from Manali, winding through the Atal Tunnel and engineering marvel, as the altitude gradually rises. Marvel at the panoramic views of snow-capped peaks. Arrive in Jispa, a serene Himalayan hamlet, where the altitude offers a taste of the high-altitude charm.",
        ],
      },
      {
        day: "3",
        title: "Jispa to Mt. Gonbo Rangjon",
        details: [
          "Continue the journey, ascending towards the majestic Mt. Gonbo Rangjon via challenging and desolate Shinkula Pass. The altitude adds a sense of awe to the rugged terrain. Experience the sublime beauty of the Himalayas at this elevated vantage point.",
          "Mt. Gonbo Rangjon is unlike any other peak you’d ever seen. It is considered a holy peak by the locals.",
        ],
      },
      {
        day: "4",
        title: "Mt. Gonbo Rangjon to Phugtal Monastery to Padum",
        details: [
          "After breakfast in the morning start your drive toward Purne.",
          "Two hour trek through the winding  Tsarap river will lead you on a spiritual exploration as you descend and head towards the ancient Phugtal Monastery. Perched on cliffs, the monastery's altitude mirrors the devotion embedded in its history.",
          "Come back and board your vehicle and ride towards Padum.",
          "Traverse the landscape from the remote monastery to the ancient town of Padum. The journey encompasses both distance and the tales of ancient trade routes, and the increasing altitude provides a backdrop of solitude and grandeur.",
        ],
      },
      {
        day: "5",
        title: "Padum to Lamayuru",
        details: [
          "Early start as one has to navigate through unpredictable water streams that take over the road and make it difficult for motorists to cross the tough patches.",
          "Venture towards Lamayuru, one of the most beautiful places that you’ll ever visit.",
          "Known as a “Moonland”, it really is very charming giving off a laid back vibes. Altitude becomes a companion, enhancing the mystical aura of the surroundings.",
        ],
      },
      {
        day: "6",
        title: "Lamayuru to Leh",
        details: [
          "Start a little late today, ascend towards Leh, a bustling town surrounded by lofty peaks. The changing altitude signals the transition from remote landscapes to a more inhabited, yet still elevated, environment.",
          "Enjoy sights such as Sangam, Gurudwara Pathar Sahib and Magnetic Hill on the way.",
          "Welcome back to civilization. Even at Leh Market.",
        ],
      },
      {
        day: "7",
        title: "Leh Town",
        details: [
          "Immerse yourself in Leh's local attractions.Visit Leh Palace and Hemis monastery. Spend some time in the market if you wish to. Visit Shanti Stupa in the evening. Enjoy cafe’s and culture of Leh.",
        ],
      },
      {
        day: "8",
        title: "Departure",
        details: [
          "After breakfast in the morning, bid adieu to this high-altitude expedition, carrying with you the memories of a journey intricately woven through altitude, spanning distances, and unveiling the rich tapestry of the Himalayan landscape.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Enjoy comfortable stays in selected hotels/ camps/home-stay/guest houses throughout the journey.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: ["Dinner from Day 1 to Breakfast on Day 9"],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Experience hassle-free travel with included transportation for all planned excursions and transfers.",
        ],
      },
      {
        sectionTitle: "Permits",
        content: [
          "Assistance in obtaining necessary permits for restricted areas, ensuring a smooth travel experience.",
        ],
      },
      {
        sectionTitle: "Sightseeing Tours",
        content: [
          "Explore Zanskars cultural and natural wonders with guided tours wherever possible.",
        ],
      },
      {
        sectionTitle: "Oxygen Support",
        content: [
          "As Ladakh boasts high altitudes, oxygen support is provided as needed to ensure the well-being of travellers.",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Expenses such as personal shopping, additional meals, or any personal activities not specified in the itinerary.",
        ],
      },
      {
        sectionTitle: "Lunch on any day",
        content: [],
      },
      {
        sectionTitle: "Tips and Gratuities",
        content: [
          "Tips for guides, drivers, and other service staff are usually not included.",
        ],
      },
      {
        sectionTitle: "Transportation to the Starting Point",
        content: [
          "The journey often starts from a specific location. Participants are responsible for reaching that location.",
        ],
      },
      {
        sectionTitle: "Additional Activities",
        content: [
          "Any optional activities or excursions not mentioned in the itinerary.",
        ],
      },
      {
        sectionTitle: "Emergency Expenses",
        content: [
          "Any expenses related to medical emergencies or unexpected events not covered by insurance.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Participants are responsible for their personal travel gear, clothing, and equipment.",
        ],
      },
      {
        sectionTitle: "Vehicle Maintenance",
        content: [
          "If participants are using a self-driven vehicle, they may be responsible for vehicle maintenance and repairs.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
  {
    individualExpeditionType: "sachPasKishtwarOverlanding",
    individualExpeditionName: "Zanskar Overlanding",
    carouselData: adventureToursHeroSection,
    location: "Zanskar, Ladakh",
    duration: "8 Days",
    grade: "Any",
    maxAltitude: "5000 m",
    bestSeason: "June, July, August, September and October",
    experienceRequired: "Don’t want to do it in a group? Get it customised.",
    mainExpeditionContent:
      "Zanskar, nestled within the Union Territory of Ladakh in northern India, is a captivating region celebrated for its breathtaking landscapes and rich cultural heritage. Surrounded by the towering peaks of the Himalayas and the Zanskar Range, it stands as one of the most isolated and challenging-to-reach areas on the Indian subcontinent. The Zanskar River, flowing through this high-altitude desert, freezes during winter, creating the renowned Chadar Trek—an adventurous journey across its icy expanse.",
    aboutExpeditionExperienced:
      "The cultural tapestry of Zanskar is woven with the threads of Tibetan Buddhism, evident in its ancient monasteries. Despite its remoteness, Zanskar has become a destination for adventure enthusiasts, offering trekking, river rafting, and an exploration of its unique monastic heritage. However, the region faces challenges due to its harsh climate, and its residents, primarily of Tibetan descent, have adapted their lives to the demanding conditions, relying on agriculture and livestock for sustenance. Zanskar, with its stunning natural beauty and resilient local culture, provides a truly distinctive and immersive experience for those who venture into its remote embrace.",
    itineraryData: [
      {
        day: "1",
        title: "Manali",
        details: [
          "Begin your adventure in the enchanting town of Manali, nestled in the lap of the Himalayas. The air is crisp, and the altitude sets the stage for the journey ahead.",
        ],
      },
      {
        day: "2",
        title: "Manali to Jispa",
        details: [
          "Depart from Manali, winding through the Atal Tunnel and engineering marvel, as the altitude gradually rises. Marvel at the panoramic views of snow-capped peaks. Arrive in Jispa, a serene Himalayan hamlet, where the altitude offers a taste of the high-altitude charm.",
        ],
      },
      {
        day: "3",
        title: "Jispa to Mt. Gonbo Rangjon",
        details: [
          "Continue the journey, ascending towards the majestic Mt. Gonbo Rangjon via challenging and desolate Shinkula Pass. The altitude adds a sense of awe to the rugged terrain. Experience the sublime beauty of the Himalayas at this elevated vantage point.",
          "Mt. Gonbo Rangjon is unlike any other peak you’d ever seen. It is considered a holy peak by the locals.",
        ],
      },
      {
        day: "4",
        title: "Mt. Gonbo Rangjon to Phugtal Monastery to Padum",
        details: [
          "After breakfast in the morning start your drive toward Purne.",
          "Two hour trek through the winding  Tsarap river will lead you on a spiritual exploration as you descend and head towards the ancient Phugtal Monastery. Perched on cliffs, the monastery's altitude mirrors the devotion embedded in its history.",
          "Come back and board your vehicle and ride towards Padum.",
          "Traverse the landscape from the remote monastery to the ancient town of Padum. The journey encompasses both distance and the tales of ancient trade routes, and the increasing altitude provides a backdrop of solitude and grandeur.",
        ],
      },
      {
        day: "5",
        title: "Padum to Lamayuru",
        details: [
          "Early start as one has to navigate through unpredictable water streams that take over the road and make it difficult for motorists to cross the tough patches.",
          "Venture towards Lamayuru, one of the most beautiful places that you’ll ever visit.",
          "Known as a “Moonland”, it really is very charming giving off a laid back vibes. Altitude becomes a companion, enhancing the mystical aura of the surroundings.",
        ],
      },
      {
        day: "6",
        title: "Lamayuru to Leh",
        details: [
          "Start a little late today, ascend towards Leh, a bustling town surrounded by lofty peaks. The changing altitude signals the transition from remote landscapes to a more inhabited, yet still elevated, environment.",
          "Enjoy sights such as Sangam, Gurudwara Pathar Sahib and Magnetic Hill on the way.",
          "Welcome back to civilization. Even at Leh Market.",
        ],
      },
      {
        day: "7",
        title: "Leh Town",
        details: [
          "Immerse yourself in Leh's local attractions.Visit Leh Palace and Hemis monastery. Spend some time in the market if you wish to. Visit Shanti Stupa in the evening. Enjoy cafe’s and culture of Leh.",
        ],
      },
      {
        day: "8",
        title: "Departure",
        details: [
          "After breakfast in the morning, bid adieu to this high-altitude expedition, carrying with you the memories of a journey intricately woven through altitude, spanning distances, and unveiling the rich tapestry of the Himalayan landscape.",
        ],
      },
    ],
    inclusion: [
      {
        sectionTitle: "Accommodation",
        content: [
          "Enjoy comfortable stays in selected hotels/ camps/home-stay/guest houses throughout the journey.",
        ],
      },
      {
        sectionTitle: "Meals",
        content: ["Dinner from Day 1 to Breakfast on Day 9"],
      },
      {
        sectionTitle: "Transportation",
        content: [
          "Experience hassle-free travel with included transportation for all planned excursions and transfers.",
        ],
      },
      {
        sectionTitle: "Permits",
        content: [
          "Assistance in obtaining necessary permits for restricted areas, ensuring a smooth travel experience.",
        ],
      },
      {
        sectionTitle: "Sightseeing Tours",
        content: [
          "Explore Zanskars cultural and natural wonders with guided tours wherever possible.",
        ],
      },
      {
        sectionTitle: "Oxygen Support",
        content: [
          "As Ladakh boasts high altitudes, oxygen support is provided as needed to ensure the well-being of travellers.",
        ],
      },
    ],
    exclusion: [
      {
        sectionTitle: "Personal Expenses",
        content: [
          "Expenses such as personal shopping, additional meals, or any personal activities not specified in the itinerary.",
        ],
      },
      {
        sectionTitle: "Lunch on any day",
        content: [],
      },
      {
        sectionTitle: "Tips and Gratuities",
        content: [
          "Tips for guides, drivers, and other service staff are usually not included.",
        ],
      },
      {
        sectionTitle: "Transportation to the Starting Point",
        content: [
          "The journey often starts from a specific location. Participants are responsible for reaching that location.",
        ],
      },
      {
        sectionTitle: "Additional Activities",
        content: [
          "Any optional activities or excursions not mentioned in the itinerary.",
        ],
      },
      {
        sectionTitle: "Emergency Expenses",
        content: [
          "Any expenses related to medical emergencies or unexpected events not covered by insurance.",
        ],
      },
      {
        sectionTitle: "Personal Gear",
        content: [
          "Participants are responsible for their personal travel gear, clothing, and equipment.",
        ],
      },
      {
        sectionTitle: "Vehicle Maintenance",
        content: [
          "If participants are using a self-driven vehicle, they may be responsible for vehicle maintenance and repairs.",
        ],
      },
    ],
    youtubeLink: "",
    expeditionDate: [
      {
        id: 1,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 2,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 3,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 4,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
      {
        id: 5,
        duration: "7 Days",
        startDate: "",
        endDate: "",
        bookedSeat: "",
        availableSeat: "",
      },
    ],
  },
];
