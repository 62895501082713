import React, { useEffect } from "react";
import Instafeed from "instafeed.js";
import "../index.css";

const InstaFeeds = () => {
  useEffect(() => {

    const userFeed = new Instafeed({
      get: "user",
      target: "instafeed-container",
      resolution: "low_resolution",
      accessToken:
        "IGQWRQSWpaeWVKdDVvSWNIVWZAsdmNEOFVyNnJwSFZAjNHBVSTRndDJiemJNM3JQTHZAWcTB0d3VGbXF5Q2RocV9oNjZASQ3Jub05FRmFCR2NLa1Q0eVBBa3NzLXBjQUxwSnM0X1hicFRRd3ZA3NV9LaEliTjRoczlBUGcZD",
      limit: 12,
      template: `
        <div class="custom-insta-post relative group sm:w-1/2 lg:w-1/3 xl:w-1/4">
          <img class="w-full aspect-square object-cover" title="{{caption}}" src="{{image}}" />
          <a href="{{link}}" target="_blank">
            <div class="opacity-0 group-hover:opacity-100 transition-opacity absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80 ">
              <p class="font-bold text-white px-5 font-sintony text-[12px] max-h-[240px] overflow-hidden overflow-y-auto text-center">{{caption}}</p>
            </div>
          </a>
        </div>
      `,
    });

    // Clear the previous content in the target container
    document.getElementById("instafeed-container").innerHTML = "";

    userFeed.run();
  }, []);

  return (
    <div
      id="instafeed-container"
      className="flex flex-col sm:flex-row sm:flex-wrap h-[556.8px] sm:h-[550.4px] md:h-[646.4px] lg:h-[590.924px] xl:h-[555.2px] 2xl:h-[651.2px] overflow-hidden overflow-y-auto no-scrollbar"
    ></div>
  );
};

export default InstaFeeds;
