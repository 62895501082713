import React, { useEffect, useState } from "react";
import { upcomingTours } from "../data/Expeditions/dataStorage";

import { FaLongArrowAltLeft } from "react-icons/fa";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaAnglesRight } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";

import AOS from "aos";
import "aos/dist/aos.css";

const UpcomingTours = (props) => {
  // const sortedExpedition5000M = JSON.parse(JSON.stringify(upcomingTours));

  // // Sort each date array within objects
  // sortedExpedition5000M.forEach((expedition) => {
  //   // Filter out past dates
  //   expedition.dates = expedition.dates.filter((date) => {
  //     const currentDate = new Date();
  //     const expeditionDate = new Date(date);
  //     return expeditionDate > currentDate; // Include only future dates
  //   });

  //   // Sort the remaining dates
  //   expedition.dates.sort((a, b) => {
  //     const dateA = new Date(a); // Convert date A to Date object
  //     const dateB = new Date(b); // Convert date B to Date object
  //     return dateA - dateB; // Compare the dates
  //   });
  // });

  const [logicForPopup, setLogicForPopup] = useState(false); 

  const sortedExpedition5000M = upcomingTours.filter((expedition) => {
    // Filter out past dates first
    const futureDates = expedition.dates.filter((dateStr) => {
      const [day, month, year] = dateStr.split(/,?\s+/);
      const expeditionDate = new Date(`${month} ${day} ${year}`);
      return expeditionDate > new Date();
    });

    // Update the expedition object with future dates and sort
    if (futureDates.length > 0) {
      expedition.dates = futureDates.sort((a, b) => {
        const [dayA, monthA, yearA] = a.split(/,?\s+/);
        const [dayB, monthB, yearB] = b.split(/,?\s+/);
        return (
          new Date(`${monthA} ${dayA} ${yearA}`) -
          new Date(`${monthB} ${dayB} ${yearB}`)
        );
      });
      return true; // Include the expedition if it has future dates
    }

    return false; // Exclude expeditions with no future dates
  });
  console.log("sortedExpedition5000M -", sortedExpedition5000M);

  // Update the popup visibility logic based on the presence of future expeditions
  useEffect(() => {
    if (sortedExpedition5000M.length === 0) {
      setLogicForPopup(false); // No upcoming expeditions, hide the popup
    } else {
      setLogicForPopup(true); // There are upcoming expeditions, show the popup
    }
  }, [sortedExpedition5000M]); // Dependency on sortedExpedition5000M to trigger the effect

  // Sort the objects based on the first date of each array
  sortedExpedition5000M.sort((a, b) => {
    const dateA = new Date(a.dates[0]); // Convert first date of object A to Date object
    const dateB = new Date(b.dates[0]); // Convert first date of object B to Date object
    return dateA - dateB; // Compare the dates
  });



  const [upcomingExpedition5000MCount, setUpcomingExpedition5000MCount] =
    useState(0);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
    {logicForPopup && 
      <section
        className="center fixed top-20 2xl:top-16 z-50"
        data-aos="fade-in"
        data-aos-duration="800"
      >
        <div className="py-6 px-4 sm:px-10 sm:py-10 md:px-14 md:py-14 lg:px-16 lg:py-16 xl:px-12 xl:py-6 2xl:px-12 2xl:py-2 bg-black">
          <IoMdClose
            size={25}
            className="text-white absolute top-5 right-5 hover:cursor-pointer"
            onClick={props.close}
          />
          <div className="grid grid-cols-12 gap-2">
            <h1 className="header col-start-1 col-span-12 text-center">
              Winter Expeditions
            </h1>

            {/* Lg device onwards  */}
            <div className="invisible hidden lg:grid lg:visible col-start-1 col-span-12 grid-cols-12 gap-2 ">
              {sortedExpedition5000M[3 * upcomingExpedition5000MCount + 0] !==
                undefined && (
                <div className="relative col-start-1 col-span-4 rounded-lg aspect-square overflow-hidden">
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 0
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                      <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              3 * upcomingExpedition5000MCount + 0
                            ].groupBy
                          }
                        </h1>
                        <div className="flex justify-between">
                          <span className="extraSmall text-white ">
                            {
                              sortedExpedition5000M[
                                3 * upcomingExpedition5000MCount + 0
                              ].days
                            }
                          </span>
                          <span className="extraSmall text-primary font-bold ">
                            {
                              sortedExpedition5000M[
                                3 * upcomingExpedition5000MCount + 0
                              ].dates[0]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 0
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            3 * upcomingExpedition5000MCount + 0
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="extraSmall text-primary">
                            View Details
                          </h1>

                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a
                        href={`https://wa.me/918894412884?text=I%20want%20to%20book%20${
                          sortedExpedition5000M[
                            3 * upcomingExpedition5000MCount + 0
                          ].title
                        }.`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="extraSmall px-2 py-1 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}

              {sortedExpedition5000M[3 * upcomingExpedition5000MCount + 1] !==
                undefined && (
                <div className="relative col-start-5 col-span-4 rounded-lg aspect-square overflow-hidden">
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 1
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                      <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              3 * upcomingExpedition5000MCount + 1
                            ].groupBy
                          }
                        </h1>
                        <div className="flex justify-between">
                          <span className="extraSmall text-white ">
                            {
                              sortedExpedition5000M[
                                3 * upcomingExpedition5000MCount + 1
                              ].days
                            }
                          </span>
                          <span className="extraSmall text-primary font-bold ">
                            {
                              sortedExpedition5000M[
                                3 * upcomingExpedition5000MCount + 1
                              ].dates[0]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 1
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            3 * upcomingExpedition5000MCount + 1
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="extraSmall text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a
                        href={`https://wa.me/918894412884?text=I%20want%20to%20book%20${
                          sortedExpedition5000M[
                            3 * upcomingExpedition5000MCount + 1
                          ].title
                        }.`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="extraSmall px-2 py-1 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}

              {sortedExpedition5000M[3 * upcomingExpedition5000MCount + 2] !==
                undefined && (
                <div className="relative col-start-9 col-span-4 rounded-lg aspect-square overflow-hidden">
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 2
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end ">
                      <div className="w-full h-20 bg-transparent flex flex-col justify-center px-4">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              3 * upcomingExpedition5000MCount + 2
                            ].groupBy
                          }
                        </h1>
                        <div className="flex justify-between">
                          <span className="extraSmall text-white ">
                            {
                              sortedExpedition5000M[
                                3 * upcomingExpedition5000MCount + 2
                              ].days
                            }
                          </span>
                          <span className="extraSmall text-primary font-bold ">
                            {
                              sortedExpedition5000M[
                                3 * upcomingExpedition5000MCount + 2
                              ].dates[0]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          3 * upcomingExpedition5000MCount + 2
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            3 * upcomingExpedition5000MCount + 2
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="extraSmall text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a
                        href={`https://wa.me/918894412884?text=I%20want%20to%20book%20${
                          sortedExpedition5000M[
                            3 * upcomingExpedition5000MCount + 2
                          ].title
                        }.`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="extraSmall px-2 py-1 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-12 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}
            </div>

            {/* sm to md device */}
            <div className="hidden invisible sm:grid sm:visible lg:hidden lg:invisible col-start-1 col-span-12 grid-cols-12 gap-2 ">
              {sortedExpedition5000M[2 * upcomingExpedition5000MCount + 0] !==
                undefined && (
                <div className="relative col-start-1 col-span-6 rounded-lg aspect-square overflow-hidden">
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          2 * upcomingExpedition5000MCount + 0
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                      <div className="w-full h-16 bg-transparent flex flex-col justify-center px-2">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              2 * upcomingExpedition5000MCount + 0
                            ].groupBy
                          }
                        </h1>
                        <div className="flex justify-between">
                          <span className="extraSmall text-white ">
                            {
                              sortedExpedition5000M[
                                2 * upcomingExpedition5000MCount + 0
                              ].days
                            }
                          </span>
                          <span className="extraSmall text-primary font-bold ">
                            {
                              sortedExpedition5000M[
                                2 * upcomingExpedition5000MCount + 0
                              ].dates[0]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          2 * upcomingExpedition5000MCount + 0
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            2 * upcomingExpedition5000MCount + 0
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="paragraph text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a
                        href={`https://wa.me/918894412884?text=I%20want%20to%20book%20${
                          sortedExpedition5000M[
                            2 * upcomingExpedition5000MCount + 0
                          ].title
                        }.`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="paragraph px-2 py-0.5 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-10 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}

              {sortedExpedition5000M[2 * upcomingExpedition5000MCount + 1] !==
                undefined && (
                <div className="relative overflow-hidden col-start-7 col-span-6 rounded-lg aspect-square">
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[
                          2 * upcomingExpedition5000MCount + 1
                        ].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end ">
                      <div className="w-full h-16 bg-transparent flex flex-col justify-center px-2">
                        <h1 className="paragraph text-primary ">
                          {
                            sortedExpedition5000M[
                              2 * upcomingExpedition5000MCount + 1
                            ].groupBy
                          }
                        </h1>
                        <div className="flex justify-between">
                          <span className="extraSmall text-white ">
                            {
                              sortedExpedition5000M[
                                2 * upcomingExpedition5000MCount + 1
                              ].days
                            }
                          </span>
                          <span className="extraSmall text-primary font-bold ">
                            {
                              sortedExpedition5000M[
                                2 * upcomingExpedition5000MCount + 1
                              ].dates[0]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[
                          2 * upcomingExpedition5000MCount + 1
                        ].title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[
                            2 * upcomingExpedition5000MCount + 1
                          ].link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="paragraph text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={20} />
                        </div>
                      </a>
                      <a
                        href={`https://wa.me/918894412884?text=I%20want%20to%20book%20${
                          sortedExpedition5000M[
                            2 * upcomingExpedition5000MCount + 1
                          ].title
                        }.`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="paragraph px-2 py-0.5 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-10 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}
            </div>

            {/* default device */}
            <div className="grid visible sm:hidden sm:invisible col-start-1 col-span-12 grid-cols-12 gap-2 ">
              {sortedExpedition5000M[upcomingExpedition5000MCount] !==
                undefined && (
                <div className="relative col-start-1 col-span-12 rounded-lg aspect-square overflow-hidden">
                  {/* Image div  */}
                  <div className="bg-primary w-full h-4/5 rounded-lg overflow-hidden relative">
                    <img
                      src={
                        sortedExpedition5000M[upcomingExpedition5000MCount].img
                      }
                      alt="Expedition Group"
                      className="w-full h-full object-cover"
                    />
                    <div className="absolute w-full h-full bg-black bg-opacity-50 top-0 left-0 hover:bg-opacity-70 ease-in-out duration-150 flex items-end">
                      <div className="w-full h-14 bg-transparent flex flex-col justify-center px-2">
                        <h1 className="tagline text-primary ">
                          {
                            sortedExpedition5000M[upcomingExpedition5000MCount]
                              .groupBy
                          }
                        </h1>
                        <div className="flex justify-between">
                          <span className="paragraph text-white ">
                            {
                              sortedExpedition5000M[
                                upcomingExpedition5000MCount
                              ].days
                            }
                          </span>
                          <span className="paragraph text-primary font-bold ">
                            {
                              sortedExpedition5000M[
                                upcomingExpedition5000MCount
                              ].dates[0]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Expedition Details  */}
                  <div className="w-full py-1 h-1/5 rounded-b-lg flex flex-col justify-center ">
                    <h1 className="tagline px-2">
                      {
                        sortedExpedition5000M[upcomingExpedition5000MCount]
                          .title
                      }
                    </h1>
                    <div className="w-full px-2 flex justify-between items-center">
                      <a
                        href={
                          sortedExpedition5000M[upcomingExpedition5000MCount]
                            .link
                        }
                      >
                        <div className="flex space-x-1 items-center cursor-pointer hover:space-x-2 ease-in-out duration-200 transition-transform">
                          <h1 className="paragraph text-primary">
                            View Details
                          </h1>
                          <FaAnglesRight className="text-primary" size={15} />
                        </div>
                      </a>
                      <a
                        href={`https://wa.me/918894412884?text=I%20want%20to%20book%20${sortedExpedition5000M[upcomingExpedition5000MCount].title}.`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button className="paragraph px-2 py-0.5 bg-primary text-black rounded-md">
                          Book Now
                        </button>
                      </a>
                    </div>
                  </div>

                  {/* <div className="absolute bg-black w-full h-8 top-0 left-0 flex justify-center items-center">
                    <h1 className="subHeader text-white">Booking Open</h1>
                  </div> */}
                </div>
              )}
            </div>

            <div className="col-start-6 col-span-2 margin flex space-x-2 justify-center items-center w-full h-auto">
              <div className="sm:invisible sm:hidden">
                <FaLongArrowAltLeft
                  className={`${
                    upcomingExpedition5000MCount > 0
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (upcomingExpedition5000MCount > 0) {
                      setUpcomingExpedition5000MCount((prevCount) =>
                        prevCount > 0 ? prevCount - 1 : 0
                      );
                    }
                  }}
                />
              </div>
              <div className="sm:invisible sm:hidden">
                <FaLongArrowAltRight
                  className={`${
                    upcomingExpedition5000MCount <
                    Math.ceil(sortedExpedition5000M.length / 1) - 1
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (
                      upcomingExpedition5000MCount <
                      Math.ceil(sortedExpedition5000M.length / 1) - 1
                    ) {
                      setUpcomingExpedition5000MCount(
                        (prevCount) => prevCount + 1
                      );
                    }
                  }}
                />
              </div>
              <div className="invisible hidden sm:visible sm:block lg:invisible lg:hidden">
                <FaLongArrowAltLeft
                  className={`${
                    upcomingExpedition5000MCount > 0
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (upcomingExpedition5000MCount > 0) {
                      setUpcomingExpedition5000MCount((prevCount) =>
                        prevCount > 0 ? prevCount - 1 : 0
                      );
                    }
                  }}
                />
              </div>
              <div className="invisible hidden sm:visible sm:block lg:invisible lg:hidden">
                <FaLongArrowAltRight
                  className={`${
                    upcomingExpedition5000MCount <
                    Math.ceil(sortedExpedition5000M.length / 2) - 1
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (
                      upcomingExpedition5000MCount <
                      Math.ceil(sortedExpedition5000M.length / 2) - 1
                    ) {
                      setUpcomingExpedition5000MCount(
                        (prevCount) => prevCount + 1
                      );
                    }
                  }}
                />
              </div>
              <div className="invisible hidden lg:visible lg:block">
                <FaLongArrowAltLeft
                  className={`${
                    upcomingExpedition5000MCount > 0
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (upcomingExpedition5000MCount > 0) {
                      setUpcomingExpedition5000MCount((prevCount) =>
                        prevCount > 0 ? prevCount - 1 : 0
                      );
                    }
                  }}
                />
              </div>
              <div className="invisible hidden lg:visible lg:block">
                <FaLongArrowAltRight
                  className={`${
                    upcomingExpedition5000MCount <
                    Math.ceil(sortedExpedition5000M.length / 3) - 1
                      ? "text-primary cursor-pointer"
                      : "text-gray-400"
                  }`}
                  size={25}
                  onClick={() => {
                    if (
                      upcomingExpedition5000MCount <
                      Math.ceil(sortedExpedition5000M.length / 3) - 1
                    ) {
                      setUpcomingExpedition5000MCount(
                        (prevCount) => prevCount + 1
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      }
    </>
  );
};

export default UpcomingTours;
